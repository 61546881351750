::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: 0;
    overflow-y: auto;
    padding: 12px;
    tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: rgb(255, 255, 255);
    margin-right: 50px;
    border-radius: .8em;
}

#msg-form-container>div.ce-quill-container>div.quill>div>div.ce-send-button-container {
    position: absolute;
    right: 5px;
    bottom: 34px !important;
}

#ce-send-message-button {
    background-color: rgb(255, 194, 14) !important;
    padding: 8px 11px !important;
}

#ce-send-message-button:hover {
    background-color: #E99125 !important;
}


.ce-message-bubble {
    background-color: rgb(223 224 231) !important;
    border-radius: 0.4em !important;
}

.ce_message {
    color: rgb(29, 29, 29) !important;
}

.ce-settings {
    background-color: #1d202f !important;
}

.ce-chat-engine, .ce-chat-list-column, .ql-toolbar {
    background-color: #1d202f !important;
}

.ce-person-text {
    color: white !important;
}

#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div.ce-chat-settings-container>div:nth-child(2) {
    color: white !important;
}

.ql-toolbar.ql-snow {
    border: 0px solid #ccc !important;
}

.ce-settings {
    border-left: 0px solid rgb(175, 175, 175) !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.ce-message-timestamp, .ce-my-message-timestamp {
    color: #333 !important;
}

.ce-message-date-text {
    color: #ebebeb !important;
}

.ce-chat-title-text, .ce-chat-subtitle-text {
    color: #ebebeb !important;
}

.ce-chat-title {
    background-color: rgb(29 32 47) !important;
}

.ce-chat-title-container {
    background-color: #1d202fe6 !important;
}

.ce-chat-feed-container {
    background-color: #1d202fe6 !important;
}

.ce-chat-subtitle-text {
    display: none !important;
}

.ce-section-title-container {
    border-radius: 10px;
    margin-top: 10px !important;
}


#ce-options-drop-down>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div.ce-photo-section>div>div.ce-section-title-container.ce-photo-title-container>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2)>div>div.ce-section-title-container.ce-person-title-container>span>svg,
#ce-options-drop-down>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div.ce-photo-section>div>div.ce-section-title-container.ce-photo-title-container>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2)>div>div.ce-section-title-container.ce-person-title-container>div {
    color: #1d202f;
}

#root>div>div.ce-wrapper>div.ce-chat-list-column>div>span>svg {
    color: #1d202f !important;
    cursor: default !important;
}

#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div:nth-child(2) {
    background-color: #323542 !important;
    color: #ebebeb !important;
}

#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2)>div>div:nth-child(2)>div>div:nth-child(5)>div>div>div:nth-child(3)>div.ce-autocomplete-close>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div.ce-chat-settings-container>div:nth-child(2),
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-list-mobile-option>div>div>div>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>span>svg,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-list-mobile-option>div>span>svg {
    color: #ccc !important;
}

#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div.ce-photo-section>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div.ce-photo-section,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(4)>div>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(4)>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2)>div,
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div:nth-child(2),
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div:nth-child(2)>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2),
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(2)>div,
#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-settings-mobile-option>div>div>div.ce-settings>div>div:nth-child(4)>div,
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div.ce-photo-section,
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div.ce-photo-section>div,
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div:nth-child(4)>div,
#root>div>div.ce-wrapper>div.ce-settings-column>div>div>div:nth-child(4)>div>div {
    border-top: 0px solid rgb(240, 240, 240) !important;
}

.ce-wrapper {
    overflow-y: clip !important;
}

.ce-chat-settings-mobile-option {
    color: #1d202f !important;
}

.ce-photo-title-container {
    border-radius: 10px !important;
    margin-bottom: 10px !important;
}

#ce-options-drop-down {
    border-radius: 10px !important;
}

#root>div>div.ce-wrapper>div.ce-chat-feed-column>div>div.ce-chat-title>div.ce-chat-list-mobile-option>div>div {
    background-color: #1d202f !important;
}

#ce-delete-chat-button {
    background-color: #1d202f !important;
}

#ce-ice-breaker-gif {
    content: url("icebreaker.gif") !important;
}

.ql-active>svg>path.ql-stroke {
    stroke: #E99125 !important;
}

.ql-active>svg>line.ql-stroke {
    stroke: #E99125 !important;
}

.ql-active>svg>path.ql-fill {
    stroke: #E99125 !important;
}

.ql-active>svg>rect.ql-fill {
    stroke: #E99125 !important;
}


.ql-active>svg>polyline.ql-stroke {
    stroke: #E99125 !important;
}


.ql-snow .ql-stroke {
    stroke: white !important;
}

.ql-fill {
    fill: white !important;
}

.anticon-paper-clip {
    color: white !important;
}

.ql-container.ql-snow {
    border: 1px solid #1d202f !important;
    background-color: #1d202f !important;
}


.ce-add-username-input {
    font-family: "" !important;
}








/* FORM STYLES */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62, 5%;
}

.erupt-login-logo {
    width: 100%;
    height: 350px;
    vertical-align: top;
}

.erupt-signup-logo {
    width: 100%;
    height: 300px;
    vertical-align: top;
}

.wrapper {
    height: 100vh;
    width: 100%;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    color: #333;
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    outline: none;
    margin-bottom: 25px;
}

.input_register {
    color: #333;
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    outline: none;
    margin-bottom: 10px;
}

.form {
    width: 400px;
    margin-bottom: 180px;
}

.title {
    text-align: center;
    color: white;
    margin-bottom: 30px;
    width: 100%;
    font-size: 2.3em;
    ;
}

.button {
    border-radius: 4px;
    border: none;
    background-color: white;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 20px;
    width: 200px;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
    width: 90%;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}